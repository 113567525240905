import Sorters from "utils/sorters";
import {formatOwner} from "../components/FileListing/SurveyItem/utils/utils";
import {getStatusAsNumber} from "./getStatusAsNumber";
import isNil from "lodash.isnil";

/**
 * sortById sorts surveys by ID.
 *
 * @param a {object} Survey object
 * @param b {object} Survey object
 * @returns {number}
 */
export const sortById = (a, b) => {
	return a.id < b.id ? -1 : 1;
};

/**
 * sortByName sorts surveys by name.
 *
 * @param a {object} Survey object
 * @param b {object} Survey object
 * @returns {number}
 */
export const sortByName = (a, b) => {
	const aName = a.name ? a.name.toLowerCase() : "";
	const bName = b.name ? b.name.toLowerCase() : "";

	return Sorters.NATURAL(aName, bName);
};

/**
 * sortByOwner sorts surveys by owner.
 *
 * @param a {object} Survey object
 * @param b {object} Survey object
 * @returns {number}
 */
export const sortByOwner = (a, b) => {
	const aOwner = formatOwner(a);
	const bOwner = formatOwner(b);

	return Sorters.NATURAL(aOwner, bOwner);
};

/**
 * sortByModified sorts surveys by date of last modification.
 *
 * @param a {object} Survey object
 * @param b {object} Survey object
 * @returns {number}
 */
export const sortByModified = (a, b) => {
	return a.modified < b.modified ? -1 : 1;
};

/**
 * sortByResponses sorts surveys by response count.
 *
 * @param a {object} Survey object
 * @param b {object} Survey object
 * @returns {number}
 */
export const sortByResponses = (a, b) => {
	const aCount = (!a.published || isNil(a.totalAnswerCount) || a.totalAnswerCount < 0) ? -1 : a.totalAnswerCount;
	const bCount = (!b.published || isNil(b.totalAnswerCount) || b.totalAnswerCount < 0) ? -1 : b.totalAnswerCount;

	return aCount - bCount;
};

/**
 * sortByNewResponses sorts surveys by new responses.
 *
 * @param a {object} survey object
 * @param b {object} survey object
 * @returns {number}
 */
export const sortByNewResponses = (a, b) => {
	const aCount = a.newAnswerCount ? a.newAnswerCount : 0;
	const bCount = b.newAnswerCount ? b.newAnswerCount : 0;
	return aCount - bCount;
};

/**
 * sortByStatus sorts surveys by status.
 *
 * @param a {object} Survey object
 * @param b {object} Survey object
 * @returns {number}
 */
export const sortByStatus = (a, b) => {
	const aStatus = getStatusAsNumber(a);
	const bStatus = getStatusAsNumber(b);

	return aStatus - bStatus;
};