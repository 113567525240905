import React, {useState} from "react";
import {connect} from "react-redux";
import {
	setSelected,
	unselect,
	addSelected,
	selectRange
} from "../../../surveysActions";
import {
	openSurvey
} from "../../Toolbar/functions/openSurvey";
import {
	formatStatus,
	formatOwner,
	formatModified
} from "./utils/utils";
import LoadingIndicator from "ui/LoadingIndicator/LoadingIndicator";
import {COLUMNS} from "../FileListing";
import "../../../surveysTypes";
import "./SurveyItem.scss";

/**
 * @callback OnContextMenu
 * @param x {number}
 * @param y {number}
 */
/**
 * SurveyItem displays a survey in the file listing.
 *
 * @param surveyId {number}
 * @param columns {Columns}
 * @param surveys {object[]}
 * @param selectedSurveyIds {number[]}
 * @param onContextMenu {OnContextMenu}
 * @param dispatch {function}
 * @return {JSX.Element}
 */
const SurveyItem = ({
	surveyId,
	columns,
	selectedSurveyIds,
	surveys,
	onContextMenu,
	dispatch
}) => {
	const toggleSelection = (e) => {
		if (!e.shiftKey && !e.ctrlKey)
		{
			if (selected && selectedSurveyIds.length === 1)
			{

				dispatch(unselect(survey.id));
				return;
			}

			dispatch(setSelected(survey.id));
			return;
		}

		if (e.ctrlKey)
		{
			if (selected)
			{
				dispatch(unselect(survey.id));
				return;
			}

			dispatch(addSelected(survey.id))
			return;
		}

		if (e.shiftKey)
		{
			if (selectedSurveyIds.length === 0)
			{
				dispatch(addSelected(survey.id));
				return;
			}

			dispatch(selectRange(survey.id));
		}
	};

	const survey = surveys.find(survey => survey.id === surveyId);
	const selected = selectedSurveyIds.indexOf(surveyId) !== -1;
	const hasNewResponses = survey.newAnswerCount && survey.newAnswerCount > 0;

	const openContextMenu = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const {x, y} = Event.parseCoordinates(e);
		dispatch(setSelected(survey.id));
		onContextMenu(x, y);
	};

	return (
		<tr
			onClick={(e) => toggleSelection(e)}
			onContextMenu={openContextMenu}
			onDoubleClick={() => openSurvey(survey)}
			className={`SurveyItem ${hasNewResponses ? "newResponses" : ""} ${selected ? "selected" : ""}`}>
			<td>{null}</td>
			<td className={"SurveyName"}>{survey.name}</td>
			{
				columns[COLUMNS.SURVEY_ID] && <td>{survey.id}</td>
			}
			{
				columns[COLUMNS.OWNER] && <td>{formatOwner(survey)}</td>
			}
			{
				columns[COLUMNS.LAST_MODIFIED] && <td>{formatModified(survey)}</td>
			}
			{
				columns[COLUMNS.RESPONSES] && <td>{survey.totalAnswerCount}</td>
			}
			{
				columns[COLUMNS.NEW_RESPONSES] && <td>{hasNewResponses ? survey.newAnswerCount : 0}</td>
			}
			{
				columns[COLUMNS.SURVEY_STATUS] && !survey.deleting &&<td>{formatStatus(survey)}</td>
			}
			{
				columns[COLUMNS.SURVEY_STATUS] && survey.deleting &&
				<td className={"DeletingStatus"}>
					<LoadingIndicator
						className={"deleting"}
						loading={true}
						text={translator.get("common.deleting")}
					/>
				</td>
			}
		</tr>
	);
};

export default connect(state => ({
	surveys: state.surveys.fetchables.surveys.surveys,
	columns: state.surveys.list.columns,
	selectedSurveyIds: state.surveys.list.selected
}))(SurveyItem)