import axios from "axios";
import {getAllTags} from "./utils/getAllTags";
import {fetchAllTemplates} from "../../templates/templateApi";
import "./surveysTypes";

export const ACTIONS = {
	CLEAR_STATE: "HOME.SURVEYS.CLEAR_STATE",
	SET_CHECKSUM: "HOME.SURVEYS.SET_CHECKSUM",

	//Survey actions
	FETCH_SURVEYS: "HOME.SURVEYS.FETCH_SURVEYS",
	SET_SURVEYS: "HOME.SURVEYS.SET_SURVEYS",
	RENAME_SURVEY: "HOME.SURVEYS.RENAME_SURVEY",
	DELETE_SURVEY: "HOME.SURVEYS.DELETE_SURVEY",
	UNDELETE_SURVEY: "HOME.SURVEYS.UNDELETE_SURVEY",
	REMOVE_SURVEY: "HOME.SURVEYS.REMOVE_SURVEY",
	ADD_SURVEY_DELETION_TASK: "HOME.SURVEY.ADD_SURVEY_DELETION_TASK",
	REMOVE_SURVEY_DELETION_TASK: "HOME.SURVEY.REMOVE_SURVEY_DELETION_TASK",
	MARK_AS_BEING_DELETED: "HOME.SURVEY.MARK_AS_BEING_DELETED",
	UNMARK_AS_BEING_DELETED: "HOME.SURVEY.UNMARK_AS_BEING_DELETED",
	POST_NEW_TAGS: "HOME.SURVEYS.POST_NEW_TAGS",
	SET_NEW_TAGS: "HOME.SURVEYS.SET_NEW_TAGS",
	MARK_SURVEYS_AS_READ: "HOME.SURVEYS.MARK_SURVEYS_AS_READ",

	//Template actions
	SET_TEMPLATES: "HOME.SURVEYS.SET_TEMPLATES",
	UPDATE_TEMPLATE: "HOME.SURVEYS.UPDATE_TEMPLATE",
	DELETE_TEMPLATE: "HOME.SURVEYS.DELETE_TEMPLATE",
	CREATE_TEMPLATE: "HOME.SURVEYS.CREATE_TEMPLATE",

	//Publish access actions
	ADD_PUBLIC_SHARE: "HOME.SURVEYS.ADD_PUBLIC_SHARE",
	REMOVE_PUBLIC_SHARE: "HOME.SURVEYS.REMOVE_PUBLIC_SHARE",

	//List actions
	SET_SELECTED: "HOME.SURVEYS.SET_SELECTED",
	ADD_SELECTED: "HOME.SURVEYS.ADD_SELECTED",
	SELECT_RANGE: "HOME.SURVEYS.SELECT_RANGE",
	UNSELECT: "HOME.SURVEYS.UNSELECT",
	UNSELECT_ALL: "HOME.SURVEYS.UNSELECT_ALL",
	SET_SORT: "HOME.SURVEYS.SET_SORT",
	TOGGLE_COLUMN: "HOME.SURVEYS.TOGGLE_COLUMN",

	//Filter actions
	CHANGE_MAIN_FILTER: "HOME.SURVEYS.CHANGE_MAIN_FILTER",
	FILTER_BY_TAG: "HOME.SURVEYS.FILTER_BY_TAG",
	SET_NEW_RESPONSES_FILTER: "HOME.SURVEYS.SET_NEW_RESPONSES_FILTERS",
	SET_TEMPLATES_FILTER: "HOME.SURVEYS.SET_TEMPLATES.FILTER",
	SET_SHARED_FILTER: "HOME.SURVEYS.SET_SHARED_FILTER",
	SET_AUTOMATIC_REPORTS_FILTER: "HOME.SURVEYS.SET_AUTOMATIC_REPORTS_FILTER",
	SET_STRING_FILTER: "HOME.SURVEYS.SET_STRING_FILTER",

	//Survey information panel actions
	FETCH_SURVEY_INFORMATION: "HOME.SURVEYS.FETCH_SURVEY_INFORMATION",
	SET_SURVEY_INFORMATION: "HOME.SURVEYS.SET_SURVEY_INFORMATION",
	FETCH_RESPONSE_TIMELINE: "HOME.SURVEYS.FETCH_RESPONSE_TIMELINE",
	SET_RESPONSE_TIMELINE: "HOME.SURVEYS.SET_RESPONSE_TIMELINE",
	FETCH_PREVIEW: "HOME.SURVEYS.FETCH_PREVIEW",
	SET_PREVIEW: "HOME.SURVEYS.SET_PREVIEW",

	//Popup actions
	TOGGLE_SURVEY_TEMPLATE_POPUP: "HOME.SURVEYS.TOGGLE_SURVEY_TEMPLATE_POPUP"
};

/**
 * clearState returns the state back to the default state.
 *
 * @returns {{type: string}}
 */
export const clearState = () => {
	return {
		type: ACTIONS.CLEAR_STATE
	};
};

/**
 * fetchSurveys initiates a get request to get all folders and surveys from backend.
 */
export const fetchSurveys = () => {
	axios.get("/app/home/survey/list", {
		params: {
			json: {
				checksum: null
			}
		}
	}).then(response => {
		if (response.status === 200)
		{
			Surveypal.store.dispatch(setSurveys({
				fetched: true,
				fetching: false,
				surveys: response.data.list,
				lastRefresh: new Date()
			}));
			Surveypal.store.dispatch(setChecksum(response.data.checksum));

			const sidebar = Surveypal.getView().getSidebar();
			sidebar.updateTags(getAllTags(response.data.list));
			sidebar.updateCounts();

			Surveypal.getLoader().hide();
		}
	})
};

/**
 * fetchTemplates fetches templates from backend and sets them in state.
 */
export const fetchTemplates = () => {
	fetchAllTemplates().then(templates => {
		Surveypal.store.dispatch(setTemplates({
			templates: templates,
			fetched: true,
			fetching: false
		}));
	}).catch(e => {
		console.error(e);
		Surveypal.store.dispatch(setTemplates({
			templates: [],
			fetched: true,
			fetching: false,
			lastRefresh: new Date()
		}));
	});
};

/**
 * setSurveys sets an array of surveys in the state.
 *
 * @param surveys {Surveys}
 * @returns {{surveys: Surveys, type: string}}
 */
export const setSurveys = (surveys) => {
	return {
		type: ACTIONS.SET_SURVEYS,
		surveys
	};
};

/**
 * deleteSurvey marks a survey in the state as deleted. Surveys aren't actually deleted from the state nor
 * backend.
 *
 * @param surveyId {number}
 * @returns {{surveyId: number, type: string}}
 */
export const deleteSurvey = (surveyId) => {
	return {
		type: ACTIONS.DELETE_SURVEY,
		surveyId
	};
};

/**
 * undeleteSurvey marks a survey as not deleted in the state.
 *
 * @param surveyId {number}
 * @returns {{surveyId: number, type: string}}
 */
export const undeleteSurvey = (surveyId) => {
	return {
		type: ACTIONS.UNDELETE_SURVEY,
		surveyId
	}
};

/**
 * removeSurvey actually removes a survey from the state.
 *
 * @param surveyId
 * @returns {{surveyId: number, type: string}}
 */
export const removeSurvey = (surveyId) => {
	return {
		type: ACTIONS.REMOVE_SURVEY,
		surveyId
	};
};

/**
 * addSurveyDeletionTask marks a survey in the state as having a deletion task associated with it.
 *
 * @param surveyId {number}
 * @param deleteAt {number}
 * @returns {{surveyId: number, deleteAt: number, type: string}}
 */
export const addSurveyDeletionTask = (surveyId, deleteAt) => {
	return {
		type: ACTIONS.ADD_SURVEY_DELETION_TASK,
		surveyId,
		deleteAt
	};
};

/**
 * removeSurveyDeletionTask marks a survey in the state as no longer having a deletion task associated with it.
 *
 * @param surveyId {number}
 * @returns {{surveyId: number, type: string}}
 */
export const removeSurveyDeletionTask = (surveyId) => {
	return {
		type: ACTIONS.REMOVE_SURVEY_DELETION_TASK,
		surveyId
	};
};

/**
 * markAsBeingDeleted adds a temporary flag to a survey item to mark that the survey is in the process of being deleted.
 *
 * @param surveyId {number}
 * @returns {{surveyId, type: string}}
 */
export const markAsBeingDeleted = (surveyId) => {
	return {
		type: ACTIONS.MARK_AS_BEING_DELETED,
		surveyId
	};
};

/**
 * unmarkAsBeingDeleted deletes the temporary flag from a survey marking them as being in the process of being deleted.
 *
 * @param surveyId {number}
 * @returns {{surveyId, type: string}}
 */
export const unmarkAsBeingDeleted = (surveyId) => {
	return {
		type: ACTIONS.UNMARK_AS_BEING_DELETED,
		surveyId
	};
};

/**
 * postNewTags posts new tags into backend and immediately sets them in the state regardless of
 * backend response.
 *
 * @param surveyId {number}
 * @param newTags {string}
 * @returns {{surveyId: number, type: string, tags: string}}
 */
export const postNewTags = (surveyId, newTags) => {
	const json = {
		id: surveyId,
		tags: newTags
	};
	axios.post("/app/home/survey/tag", `json=${encodeURIComponent(JSON.stringify(json))}`)

	return {
		type: ACTIONS.SET_NEW_TAGS,
		surveyId,
		newTags
	};
};

/**
 * postSurveysAsRead posts requests to backend to mark surveys as read, then creates an action to mark them read also
 * in the state.
 *
 * @param surveyIds {number[]}
 */
export const postSurveysAsRead = (surveyIds) => {
	Promise.all(surveyIds.map(surveyId => axios.post("/app/home/survey/seen", {id: surveyId})))
		.then((responses) => {
			Surveypal.store.dispatch(markSurveysAsRead(surveyIds))
		});
};

/**
 * markSurveyAsRead marks survey as read in the state.
 *
 * @param surveyIds {number[]}
 * @returns {{surveyIds: number[], type: string}}
 */
export const markSurveysAsRead = (surveyIds) => {
	Surveypal.getView().getSidebar().updateCounts();

	return {
		type: ACTIONS.MARK_SURVEYS_AS_READ,
		surveyIds
	};
};

/**
 * renameSurvey renames a survey in the state.
 *
 * @param surveyId {number}
 * @param newName {string}
 * @returns {{surveyId: number, newName: string, type: string}}
 */
export const renameSurvey = (surveyId, newName) => {
	return {
		type: ACTIONS.RENAME_SURVEY,
		surveyId,
		newName
	};
};

/**
 * setTemplates sets fetched templates to state.
 *
 * @param templates {Templates}
 * @returns {{templates: Templates, type: string}}
 */
export const setTemplates = (templates) => {
	return {
		type: ACTIONS.SET_TEMPLATES,
		templates
	};
};

/**
 * updateTemplate updates a template in the state, not in backend.
 *
 * @param newTemplate {object}
 * @returns {{newTemplate: object, type: string, templateId}}
 */
export const updateTemplate = (newTemplate) => {
	return {
		type: ACTIONS.UPDATE_TEMPLATE,
		newTemplate
	};
};

/**
 * deleteTemplate deletes a template from state, not backend.
 *
 * @param deletedTemplate
 * @returns {{type: string, deletedTemplate: object}}
 */
export const deleteTemplate = (deletedTemplate) => {
	return {
		type: ACTIONS.DELETE_TEMPLATE,
		deletedTemplate
	};
};

/**
 * createTemplate adds a new template in the state, not backend.
 *
 * @param newTemplate {object}
 * @returns {{newTemplate: object, type: string}}
 */
export const createTemplate = (newTemplate) => {
	return {
		type: ACTIONS.CREATE_TEMPLATE,
		newTemplate
	};
};

/**
 * addPublicShare marks a survey in the state as being publicly shared.
 *
 * @param surveyId {number}
 * @param link {string}
 * @returns {{surveyId: number, link: string, type: string}}
 */
export const addPublicShare = (surveyId, link) => {
	return {
		type: ACTIONS.ADD_PUBLIC_SHARE,
		surveyId,
		link
	};
};

/**
 * removePublicShare unmarks a survey in the state as being publicly shared.
 *
 * @param surveyId {number}
 * @returns {{surveyId: number, type: string}}
 */
export const removePublicShare = (surveyId) => {
	return {
		type: ACTIONS.REMOVE_PUBLIC_SHARE,
		surveyId
	};
};

/**
 * setChecksum sets the checksum in the state.
 *
 * @param checksum {number}
 * @returns {{checksum: number, type: string}}
 */
export const setChecksum = (checksum) => {
	return {
		type: ACTIONS.SET_CHECKSUM,
		checksum
	};
};

/**
 * setSelected sets a single survey ID as the selected survey.
 *
 * @param surveyId {number}
 * @returns {{type: string, surveyIds: number[]}}
 */
export const setSelected = (surveyId) => {
	return {
		type: ACTIONS.SET_SELECTED,
		surveyId
	};
};

/**
 * addSelected adds a survey ID as a selected surveys.
 *
 * @param surveyId {number}
 * @returns {{type: string, surveyId: number}}
 */
export const addSelected = (surveyId) => {
	return {
		type: ACTIONS.ADD_SELECTED,
		surveyId
	};
};

/**
 * selectRange selects a range of surveys between the first selected and the clicked survey.
 *
 * @param surveyId {number}
 * @returns {{type: string, surveyId: number}}
 */
export const selectRange = (surveyId) => {
	return {
		type: ACTIONS.SELECT_RANGE,
		surveyId
	};
};

/**
 * unselect removes a survey ID from the list of selected surveys.
 *
 * @param surveyId {number}
 * @returns {{type: string, surveyId: number}}
 */
export const unselect = (surveyId) => {
	return {
		type: ACTIONS.UNSELECT,
		surveyId: surveyId
	};
};

/**
 * unselectAll clears the state of selected surveys.
 *
 * @returns {{type: string}}
 */
export const unselectAll = () => {
	return {
		type: ACTIONS.UNSELECT_ALL
	};
};

/**
 * setSort sorts surveys using the given sorting function.
 *
 * @param sort {Sort}
 * @returns {{sort: Sort, type: string}}
 */
export const setSort = (sort) => {
	if (typeof sort.direction !== "number")
	{
		throw new Error(`Surveys Actions: sortSurveys: Direction must be of type 'number'. Given direction is of type ${typeof sort.direction}`);
	}

	if (sort.direction !== -1 && sort.direction !== 1)
	{
		throw new Error(`Surveys Actions: sortSurveys: Direction must be either -1 (for descending) or 1 (for ascending). Given value is ${sort.direction}.`)
	}

	return {
		type: ACTIONS.SET_SORT,
		sort
	};
};

/**
 * toggleColumn switches columns on or off in the survey list table.
 *
 * @param column {string}
 * @returns {{column: string, type: string}}
 */
export const toggleColumn = (column) => {
	return {
		type: ACTIONS.TOGGLE_COLUMN,
		column
	};
};

/**
 * changeMainFilter changes the main filter (your surveys, shared with you etc.) for the survey listing.
 *
 * @param mainFilter {string}
 * @returns {{mainFilter: function, type: string}}
 */
export const changeMainFilter = (mainFilter) => {
	return {
		type: ACTIONS.CHANGE_MAIN_FILTER,
		mainFilter
	};
};

/**
 * filterByTag makes the survey list filter surveys by a tag.
 *
 * @param tag {string}
 * @returns {{tag: string, type: string}}
 */
export const filterByTag = (tag) => {
	return {
		type: ACTIONS.FILTER_BY_TAG,
		tag
	};
};

/**
 * setNewResponsesFilter sets whether to filter surveys by new responses.
 *
 * @param newResponsesFilter {SecondaryFilter}
 * @returns {{type: string, newResponsesFilter: SecondaryFilter}}
 */
export const setNewResponsesFilter = (newResponsesFilter) => {
	return {
		type: ACTIONS.SET_NEW_RESPONSES_FILTER,
		newResponsesFilter
	};
};

/**
 * setTemplatesFilter sets whether to filter surveys by whether the survey is used as a template or not.
 *
 * @param templatesFilter {SecondaryFilter}
 * @returns {{templatesFilter: SecondaryFilter, type: string}}
 */
export const setTemplatesFilter = (templatesFilter) => {
	return {
		type: ACTIONS.SET_TEMPLATES_FILTER,
		templatesFilter
	};
};

/**
 * setSharedFilter sets whether to filter surveys by whether the survey is shared to someone.
 *
 * @param sharedFilter {SecondaryFilter}
 * @returns {{sharedFilter: SecondaryFilter, type: string}}
 */
export const setSharedFilter = (sharedFilter) => {
	return {
		type: ACTIONS.SET_SHARED_FILTER,
		sharedFilter
	};
};

/**
 * setAutomaticReportsFilters sets whether to filter by whether the survey has automatic reports.
 *
 * @param automaticReportsFilter {SecondaryFilter}
 * @returns {{type: string, automaticReportsFilter: SecondaryFilter}}
 */
export const setAutomaticReportsFilter = (automaticReportsFilter) => {
	return {
		type: ACTIONS.SET_AUTOMATIC_REPORTS_FILTER,
		automaticReportsFilter
	};
};

/**
 * setNameFilter sets a filter string by which surveys are filtered based on name.
 *
 * @param stringFilter {string}
 * @returns {{type: string, stringFilter: string}}
 */
export const setStringFilter = (stringFilter) => {
	return {
		type: ACTIONS.SET_STRING_FILTER,
		stringFilter
	};
};

/**
 * fetchSurveyInformation fetches survey information for the survey list side panel.
 *
 * @param surveyId
 */
export const fetchSurveyInformation = (surveyId) => {
	Surveypal.store.dispatch(setSurveyInformation({
		fetched: false,
		fetching: true,
		surveyInformation: undefined
	}));

	axios.get("/app/home/survey", {
		params: {
			json: {
				id: surveyId
			}
		}
	}).then((response) => {
		Surveypal.store.dispatch(setSurveyInformation({
			fetched: true,
			fetching: false,
			surveyInformation: response.data
		}));
	});
};

/**
 * fetchResponseTimeline fecthes the timeline of response activity for the given survey.
 *
 * @param surveyId {number}
 */
export const fetchResponseTimeline = (surveyId) => {
	Surveypal.store.dispatch(setResponseTimeline({
		fetched: false,
		fetching: true,
		responseTimeline: undefined
	}));

	axios.get("/app/survey/response/chronology", {
		params: {
			json: {
				targetId: surveyId,
				timeRange: {}
			}
		}
	}).then((response) => {
		Surveypal.store.dispatch(setResponseTimeline({
			fetched: true,
			fetching: false,
			responseTimeline: response.data
		}));
	});
};

/**
 * setResponseTimeline sets response timeline data and fetching status to state.
 *
 * @param responseTimeline {ResponseTimeline}
 * @returns {{responseTimeline: ResponseTimeline, type: string}}
 */
export const setResponseTimeline = (responseTimeline) => {
	return {
		type: ACTIONS.SET_RESPONSE_TIMELINE,
		responseTimeline
	};
};

/**
 * fetchPreview fetches the preview image of the given survey.
 *
 * @param surveyId {number}
 */
export const fetchPreview = (surveyId) => {
	//The first dispatch will force the use of an iframe to display survey preview.
	//Remove it or comment it out to fetch review picture.
	Surveypal.store.dispatch(setPreview({
		fetched: true,
		fetching: false,
		preview: undefined
	}));
	return;

	Surveypal.store.dispatch(setPreview({
		fetched: false,
		fetching: true,
		preview: undefined
	}));

	axios.get("/app/home/survey/preview", {
		params: {
			json: {
				id: surveyId
			}
		}
	}).then((response) => {
		Surveypal.store.dispatch(setPreview({
			fetched: true,
			fetching: false,
			preview: response.data
		}));
	}).catch((response) => {
		console.error({response});
		Surveypal.store.dispatch(setPreview({
			fetched: true,
			fetching: false,
			preview: undefined
		}));
	});
};

/**
 * setPreview sets the fetching status and preview image URL to state.
 *
 * @param preview {Preview}
 * @returns {{preview: Preview, type: string}}
 */
export const setPreview = (preview) => {
	return {
		type: ACTIONS.SET_PREVIEW,
		preview
	};
};

/**
 * setSurveyInformation sets given survey into state as the survey to be displayed in the
 * survey information panel.
 *
 * @param surveyInformation {SurveyInformation}
 * @returns {{surveyInformation: object|undefined, type: string}}
 */
export const setSurveyInformation = (surveyInformation) => {
	return {
		type: ACTIONS.SET_SURVEY_INFORMATION,
		surveyInformation
	};
};

/**
 * toggleSurveyTemplatePopup either shows or hides the survey template creation/edit popup.
 *
 * @returns {{type: string}}
 */
export const toggleSurveyTemplatePopup = () => {
	return {
		type: ACTIONS.TOGGLE_SURVEY_TEMPLATE_POPUP
	};
};