define("monitors/version-monitor", function (require)
{
	const VERSION = "2024-09-09-1725875898226";
	const axios = require("core/axios-sp").default;
	var PollMonitor = require("monitors/poll-monitor");
	var VersionNotification = require("notifications/version-notification");
	var Topics = require("conf/topics");

	return PollMonitor.extend(
		{
			init: function ()
			{
				this.supreme(15000);
				this._canShowNotification = true;

				Surveypal.events.subscribe(Topics.GENERAL.LOGIN.topic, function()
				{
					this._canShowNotification = true;
				}.bind(this));
				Surveypal.events.subscribe(Topics.GENERAL.LOGOUT.topic, function()
				{
					this._canShowNotification = false;
					this.removeNotification();
				}.bind(this));

				this.start();
			},

			poll: function ()
			{
				if (!this.canPoll())
				{
					return;
				}
				axios.get("/version.json")
					.then((response) => {
						if (response.data && response.data.version !== VERSION)
						{
							this.versionChanged();
						}
					})
					.catch(e => {
						console.warn("Version monitor couldn't find version.json file.");
						console.error(e);
					});
			},

			removeNotification: function()
			{
				if ((this.notification instanceof VersionNotification))
				{
					this.notification.deinit();
					this.notification = null;
				}
			},

			versionChanged: function ()
			{
				this.removeNotification();

				// Show version changed notification
				if (!this.notification && this._canShowNotification)
				{
					this.notification = new VersionNotification();
				}
			}
		});
});