import axios from "core/axios-sp";
import SavePopup from "views/survey/save-popup";
import {fetchSurveys} from "../../../surveysActions";
import EVENTS from "conf/ai-events";

/**
 * requestSurveyDuplication sends a POST request to backend for duplicating a survey, then refetches
 * all surveys from backend.
 *
 * @param surveyId {number}
 * @param newName {string}
 */
export const requestSurveyDuplication = (surveyId, newName) => {
	Surveypal.getLoader().show();

	const params = {
		id: surveyId,
		name: newName
	};

	const postData = `json=${encodeURIComponent(JSON.stringify(params))}`;

	axios.post("/app/home/survey/duplicate", postData)
		.then((response) => {
			Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_DUPLICATED);
			fetchSurveys();
		});
};

/**
 * showDuplicatePopup opens the survey duplication popup where the user can input the new name of
 * the new survey.
 *
 * @param survey {object}
 */
export const showDuplicatePopup = (survey) => {
	new SavePopup(
		(newName) => requestSurveyDuplication(survey.id, newName),
		"survey-save-as",
		translator.get("saveas-popup.saveTitle"),
		translator.get("saveas-popup.saveDesc"),
		translator.get("saveas-popup.save"),
		survey.name + translator.get("saveas-popup.copyOf")
	);
};