import React, {useState, useEffect, useMemo} from "react";
import {connect} from "react-redux";
import SurveyItem from "./SurveyItem/SurveyItem";
import SortIndicator from "./SortIndicator/SortIndicator";
import ColumnSelection from "./ColumnSelection/ColumnSelection";
import ContextMenu from "./ContextMenu/ContextMenu";
import {
	sortByName,
	sortById,
	sortByOwner,
	sortByModified,
	sortByResponses,
	sortByNewResponses,
	sortByStatus
} from "../../utils/sorters";
import {
	setSort,
	postSurveysAsRead
} from "../../surveysActions";
import Filters from "../../utils/filters";
import SecondaryFilters from "../../utils/secondaryFilters";
import StringFilter from "../../utils/stringFilter";
import "../../surveysTypes";
import "./FileListing.scss";

export const COLUMNS = {
	NAME: "name",
	SURVEY_ID: "survey-id",
	OWNER: "owner",
	LAST_MODIFIED: "last-modified",
	RESPONSES: "responses",
	NEW_RESPONSES: "new-responses",
	PUBLIC_SHARE: "public-share",
	SURVEY_STATUS: "survey-status"
};

/**
 * FileListing lists the given folders and surveys.
 *
 * @param surveys {object[]}
 * @param templates {object[]}
 * @param filters {Filters}
 * @param columns {Columns}
 * @param sort {Sort}
 * @param selected {number[]}
 * @param dispatch {function}
 * @returns {JSX.Element}
 */
const FileListing = ({
	surveys,
	templates,
	filters,
	columns,
	sort,
	selected,
	dispatch
}) => {
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [contextMenuStyle, setContextMenuStyle] = useState({});

	const toggleContextMenu = (x, y) => {
		setShowContextMenu(true);
		setContextMenuStyle({
			left: x,
			top: y
		});
	};

	useEffect(() => {
		const unreadSelectedSurveys = selected.filter(surveyId => {
			const selectedSurvey = surveys.find(survey => survey.id === surveyId);
			return selectedSurvey.newAnswerCount && selectedSurvey.newAnswerCount > 0;
		});

		if (unreadSelectedSurveys.length > 0) postSurveysAsRead(unreadSelectedSurveys);
	}, [selected]);

	const toggleSort = (column, sorter) => {
		const newSort = {
			column,
			direction: -sort.direction,
			sorter
		};
		dispatch(setSort(newSort));
	};

	const mainFilter = (() => {
		if (filters.tag !== undefined) return (survey) => {
			return Filters.taggedSurveys(survey, [filters.tag]);
		};

		if (filters.mainFilter === Filters.FILTER_NAMES.TAGGED_SURVEYS) return Filters[Filters.FILTER_NAMES.ZERO_SURVEYS];

		return Filters[filters.mainFilter];
	})();

	const filteredSurveys = useMemo(() => surveys
		.filter(mainFilter)
		.filter((survey) => SecondaryFilters.surveyHasNewResponses(survey, filters.newResponsesFilter))
		.filter((survey) => SecondaryFilters.surveyIsShared(survey, filters.sharedFilter))
		.filter((survey) => SecondaryFilters.surveyIsTemplate(survey, templates, filters.templatesFilter))
		.filter((survey) => SecondaryFilters.surveyHasAutomaticReports(survey, filters.automaticReportsFilter))
		.filter((survey) => StringFilter(survey, filters.stringFilter)),
	[surveys, sort, columns, filters]);

	return (
		<div className={"SurveyFileListing"}>
			<table>
				<thead className={"SurveyFileListingHeader"}>
					<tr>
						<th className={"unsortable columnSelectionColumn"}>
							<ColumnSelection/>
						</th>
						<th className={`SurveyName ${sort.column === COLUMNS.NAME ? "sort" : ""}`} onClick={() => toggleSort(COLUMNS.NAME, sortByName)}>
							{translator.get("home.nameHeader")}
							<SortIndicator column={COLUMNS.NAME}/>
						</th>
						{
							columns[COLUMNS.SURVEY_ID] &&
							<th
								className={sort.column === COLUMNS.SURVEY_ID ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.SURVEY_ID, sortById)}>
								{translator.get("common.id")}
								<SortIndicator column={COLUMNS.SURVEY_ID}/>
							</th>
						}
						{
							columns[COLUMNS.OWNER] &&
							<th
								className={sort.column === COLUMNS.OWNER ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.OWNER, sortByOwner)}>
								{translator.get("home.ownerHeader")}
								<SortIndicator column={COLUMNS.OWNER}/>
							</th>
						}
						{
							columns[COLUMNS.LAST_MODIFIED] &&
							<th
								className={sort.column === COLUMNS.LAST_MODIFIED ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.LAST_MODIFIED, sortByModified)}>
								{translator.get("home.modifiedHeader")}
								<SortIndicator column={COLUMNS.LAST_MODIFIED}/>
							</th>
						}
						{
							columns[COLUMNS.RESPONSES] &&
							<th
								className={sort.column === COLUMNS.RESPONSES ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.RESPONSES, sortByResponses)}>
								{translator.get("home.answerCountHeader")}
								<SortIndicator column={COLUMNS.RESPONSES}/>
							</th>
						}
						{
							columns[COLUMNS.NEW_RESPONSES] &&
							<th
								className={sort.column === COLUMNS.NEW_RESPONSES ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.NEW_RESPONSES, sortByNewResponses)}>
								{translator.get("home.newResponsesHeader")}
								<SortIndicator column={COLUMNS.NEW_RESPONSES}/>
							</th>
						}
						{
							columns[COLUMNS.PUBLIC_SHARE] &&
							<th
								className={sort.column === COLUMNS.PUBLIC_SHARE ? "sort" : ""}
								onClick={() => {}}>
								{translator.get("home.publicShareHeader")}
								<SortIndicator column={COLUMNS.PUBLIC_SHARE}/>
							</th>
						}
						{
							columns[COLUMNS.SURVEY_STATUS] &&
							<th
								className={sort.column === COLUMNS.SURVEY_STATUS ? "sort" : ""}
								onClick={() => toggleSort(COLUMNS.SURVEY_STATUS, sortByStatus)}>
								{translator.get("home.statusHeader")}
								<SortIndicator column={COLUMNS.SURVEY_STATUS}/>
							</th>
						}
					</tr>
				</thead>
				<tbody>
				{
					filteredSurveys.map((survey) => (
						<SurveyItem
							onContextMenu={(x, y) => toggleContextMenu(x, y)}
							key={`surveyitem-${survey.id}`}
							surveyId={survey.id}
						/>
					))
				}
				</tbody>
			</table>
			{
				showContextMenu &&
				<ContextMenu
					onClose={() => setShowContextMenu(false)}
					style={contextMenuStyle}
				/>
			}
		</div>
	);
};

export default connect(state => ({
	surveys: state.surveys.fetchables.surveys.surveys,
	templates: state.surveys.fetchables.templates.templates,
	filters: state.surveys.list.filters,
	selected: state.surveys.list.selected,
	columns: state.surveys.list.columns,
	sort: state.surveys.list.sort
}))(FileListing);