import {undeleteSurvey} from "../../../surveysActions";
import axios from "core/axios-sp";

/**
 * restoreSurvey marks the selected surveys as no longer deleted.
 *
 * @param surveyIds {number[]}
 */
export const restoreSurvey = (surveyIds) => {
	surveyIds.forEach((surveyId) => {
		const params = {
			id: surveyId
		};

		const postData = encodeURI("json=" + JSON.stringify(params));

		axios.post("/app/home/survey/undelete", postData).then((response) => {
			if (response.status === 200)
			{
				Surveypal.store.dispatch(undeleteSurvey(surveyId));
			}
		});
	});
};