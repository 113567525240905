import axios from "core/axios-sp";
import SavePopup from "views/survey/save-popup";
import {renameSurvey} from "../../../surveysActions";
import EVENTS from "conf/ai-events";

/**
 * requestSurveyRename sends a POST request to backend for renaming a survey, then modifies the
 * survey list in state with the updated name.
 *
 * @param surveyId {number}
 * @param newName {string}
 */
export const requestSurveyRename = (surveyId, newName) => {
	Surveypal.getLoader().show();

	const params = {
		id: surveyId,
		name: newName
	};

	const postData = `json=${encodeURIComponent(JSON.stringify(params))}`;

	axios.post("/app/home/survey/rename", postData)
		.then((response) => {
			Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_RENAMED);
			Surveypal.store.dispatch(renameSurvey(surveyId, newName));
			Surveypal.getLoader().hide();
		});
};

/**
 * showRenamePopup opens the survey renaming popup where the user can input the new name of
 * the survey.
 *
 * @param survey {object}
 */
export const showRenamePopup = (survey) => {
	new SavePopup(
		(newName) => requestSurveyRename(survey.id, newName),
		"survey-rename",
		translator.get("rename-popup.renameTitle"),
		translator.get("rename-popup.renameDesc"),
		translator.get("rename-popup.rename"),
		survey.name
	);
};