import DeletePopup from "views/home/surveys/DeletePopup/delete-popup";
import DeletePermanentPopup from "views/home/surveys/DeletePermanentPopup/delete-permanent-popup";
import axios from "core/axios-sp";
import EVENTS from "conf/ai-events";
import {
	deleteSurvey as markSurveyAsDeleted,
	removeSurvey,
	addSurveyDeletionTask,
	markAsBeingDeleted,
	unmarkAsBeingDeleted,
	toggleSurveyDeletionErrorPopup
} from "../../../surveysActions";

/**
 * requestDeletionTaskCreation creates a deletion task for surveys where the truly deletion request returned a 412.
 *
 * @param surveyId {number}
 */
export const requestDeletionTaskCreation = (surveyId) => {
	axios.put("/app/survey/deletion/task", {
		surveyId
	}).then((response) => {
		if (response.status === 200)
		{
			Surveypal.store.dispatch(unmarkAsBeingDeleted(surveyId));
			Surveypal.store.dispatch(addSurveyDeletionTask(surveyId, response.data.deleteAt));
		}
	});
};

/**
 * requestSurveyDeletion does one of two things depending on if the given survey is marked as deleted:
 *
 * - If the survey is not marked as deleted, the user is attempting to delete it from one of the non-deleted lists. In
 *   this case, the survey will be marked as deleted in backend and state.
 * - If the survey is marked as deleted, the user is attempting to delete it from the list of already deleted surveys.
 *   In this case, the survey will be truly deleted from state and backend.
 *
 * The truly deletion will itself have one of three outcomes:
 *
 * - If the deletion request returns a 200, the survey is truly deleted immediately.
 * - If the deletion request returns a 209, the deletion is cancelled and a popup will be displayed that the survey
 *   cannot be deleted.
 * - If the deletion request returns a 412, a deletion task is created and the survey will be truly deleted only when
 *   that task is run. A 412 is usually returned when the survey has a large number of answers and deleting those
 *   answers in itself is a big job.
 *
 * @param survey {object}
 */
export const requestSurveyDeletion = (survey) => {
	Surveypal.applicationInsights.trackEvent(EVENTS.SURVEY_DELETED);

	//If survey isn't yet marked as deleted, simply mark it as deleted in backend and state.
	if (!survey.deleted)
	{
		Surveypal.store.dispatch(markSurveyAsDeleted(survey.id));

		const params = {
			id: survey.id
		};

		const postData = encodeURI("json=" + JSON.stringify(params));

		axios.post("/app/home/survey/delete", postData);
		return;
	}

	//Otherwise truly delete it.
	Surveypal.store.dispatch(markAsBeingDeleted(survey.id));
	axios.post("/app/home/survey/delete/permanent", {
		id: survey.id
	}).then((response) => {
		//200 = was deleted immediately, can be removed from state.
		if (response.status === 200)
		{
			Surveypal.store.dispatch(removeSurvey(survey.id));
		}
	}).catch((response) => {
		if (response.response.status === 409)
		{
			Surveypal.store.dispatch(toggleSurveyDeletionErrorPopup(true, response.response.data.reason));
			Surveypal.store.dispatch(unmarkAsBeingDeleted(survey.id));
		}
		//412 - precondition failed, create deletion task instead
		if (response.response.status === 412)
		{
			requestDeletionTaskCreation(survey.id);
		}
	});
};

/**
 * runDeletion executes the survey deletion request for all selected surveys.
 *
 * @param surveys {object[]}
 */
const runDeletion = (surveys) => {
	surveys.forEach((survey) => requestSurveyDeletion(survey));
};

/**
 * deleteSurvey displays a popup for the user to confirm survey deletion.
 *
 * If the survey is not marked as deleted (i.e. not in the "deleted" category), then the user is shown a popup for
 * confirming the initial deletion of the survey.
 *
 * If the survey is marked as deleted (i.e. in the "deleted" category), then the user is shown a popup for confirming
 * the permanent deletion of the survey.
 *
 * @param surveys {object[]}
 */
export const deleteSurvey = (surveys) => {
	if (!surveys[0].deleted)
	{
		new DeletePopup(() => runDeletion(surveys), surveys.length > 1);
		return;
	}

	new DeletePermanentPopup(() => runDeletion(surveys), surveys.length > 1);
};