import Hash from "utils/hash";

export const newSurvey = () => {
	Surveypal.getIntercom().event("create-survey");

	const hash = new Hash();
	if (hash.get("view") === "home/templates")
	{
		Surveypal.getView().getSubview().resetView();
		return;
	}

	hash.set("view", "home/templates");
	hash.apply();
};