import DateAndTime from "ui/DateAndTime/DateAndTime";

export const formatStatus = (survey) => {
	if (survey.deleted)
	{
		if (survey.deleteAt)
		{
			return translator.get("home.statusDeleteAt").replace("${date}", DateAndTime({
				date: survey.deleteAt
			}));
		}

		return translator.get("home.statusDeleted");
	}

	if (!survey.published)
	{
		return translator.get("home.statusDraft");
	}

	const now = Date.now();

	if (survey.active && survey.startDate && survey.startDate > now)
	{
		//Active and time framed, but start date in future
		return `${translator.get("home.statusSurveyOpensAt")} ${translator.timestamp("Date.shortDate"), survey.startDate}`;
	}

	if (survey.endDate && survey.endDate < now)
	{
		//Active/inactive and time framed, but end date in past
		return translator.get("home.statusSurveyClosedSchedule");
	}

	if (survey.active && survey.endDate && survey.startDate < now < survey.endDate)
	{
		//Active and time framed, but end date in future (and start date in past).
		return `${translator.get("home.statusSurveyOpenUntil")} ${translator.timestamp("Date.shortDate", survey.endDate)}`;
	}

	if (survey.active)
	{
		// Active without time frame
		return translator.get("home.statusSurveyOpen");
	}

	if (!survey.active)
	{
		// Inactive without time frame
		return translator.get("home.statusSurveyClosed");
	}

	return "";
};