import {FILTER_VALUES} from "../components/Filters/Filters";
import AccessConf from "conf/survey-access";

export const surveyHasNewResponses = (survey, value) => {
	if (value === FILTER_VALUES.YES) return survey.newAnswerCount && survey.newAnswerCount > 0;
	if (value === FILTER_VALUES.NO) return !survey.newAnswerCount || survey.newAnswerCount === 0;

	return true;
};

export const surveyIsShared = (survey, value) => {
	if (value === FILTER_VALUES.YES) return survey.shared && !AccessConf.isTeamShared(survey);
	if (value === FILTER_VALUES.NO) return !survey.shared;

	return true;
};

export const surveyIsTemplate = (survey, templates, value) => {
	const isTemplate = templates.some(template => template.survey_id === survey.id);
	if (value === FILTER_VALUES.YES) return isTemplate;
	if (value === FILTER_VALUES.NO) return !isTemplate;

	return true;
};

const surveyHasAutomaticReports = (survey, value) => {
	if (value === FILTER_VALUES.YES) return !!survey.scheduledExport;
	if (value === FILTER_VALUES.NO) return !survey.scheduledExport;

	return true;
};

export default {
	surveyHasNewResponses,
	surveyIsShared,
	surveyIsTemplate,
	surveyHasAutomaticReports
};