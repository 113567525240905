import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {newSurvey} from "../../../../utils/newSurvey";
import {openSurvey} from "../../Toolbar/functions/openSurvey";
import {showDuplicatePopup} from "../../Toolbar/functions/duplicateSurvey";
import {showRenamePopup} from "../../Toolbar/functions/renameSurvey";
import {showSharePopup} from "../../Toolbar/functions/shareSurvey";
import {showSurveyTransferPopup} from "../../Toolbar/functions/transferSurvey";
import {toggleSharedAccess} from "../../Toolbar/functions/toggleSharedAccess";
import {surveyTemplate} from "../../Toolbar/functions/surveyTemplate";
import {launchSurvey} from "../../Toolbar/functions/launchSurvey";
import {closeSurvey} from "../../Toolbar/functions/closeSurvey";
import {reopenSurvey} from "../../Toolbar/functions/reopenSurvey";
import {deleteSurvey} from "../../Toolbar/functions/deleteSurvey";
import {restoreSurvey} from "../../Toolbar/functions/restoreSurvey";
import {cancelDeletion} from "../../Toolbar/functions/cancelDeletion";
import FILTERS from "../../../utils/filters";
import Roles from "conf/roles";
import "./ContextMenu.scss";

/**
 * @callback OnClose
 */
/**
 * ContextMenu appears when the user clicks on a survey in the survey listing using the second mouse
 * button. ContextMenu should contain the same items as the toolbar.
 *
 * Note: When modifying items in the context menu, remember to make the same modifications in the toolbar.
 *
 * @param onClose {OnClose}
 * @param style {object}
 * @param surveys {object[]}
 * @param selected {number[]}
 * @param mainFilter {string}
 * @returns {JSX.Element}
 */
const ContextMenu = ({
	onClose,
	style,
	surveys,
	selected,
	mainFilter
}) => {
	const [finalStyle, setFinalStyle] = useState({
		...style,
		visibility: "hidden"
	});
	const menuReference = useRef();

	useEffect(() => {
		const menuRectangle = menuReference.current.getBoundingClientRect();
		const windowWidth = window.getWidth();
		const windowHeight = window.getHeight();
		setFinalStyle({
			visibility: "visible",
			left: style.left + menuRectangle.width < windowWidth ? style.left : windowWidth - menuRectangle.width + "px",
			top: style.top + menuRectangle.height < windowHeight ? style.top : windowHeight - menuRectangle.height + "px"
		});
	}, []);

	const firstSelectedSurvey = surveys.find(survey => survey.id === selected[0]);
	const selectedSurveys = surveys.filter(survey => selected.indexOf(survey.id) !== -1);

	const newSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length === 0,
		onClick: newSurvey
	};

	const openSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length === 0,
		onClick: () => openSurvey(firstSelectedSurvey)
	};

	const duplicateSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => showDuplicatePopup(firstSelectedSurvey)
	};

	const renameSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => showRenamePopup(firstSelectedSurvey)
	};

	const shareSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => showSharePopup(firstSelectedSurvey)
	};

	const transferSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => showSurveyTransferPopup(selected, "")
	};

	const sharedAccessProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => toggleSharedAccess(firstSelectedSurvey)
	};

	const surveyTemplateProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => surveyTemplate()
	};

	const launchSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => launchSurvey(selected[0])
	};

	const closeSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => closeSurvey(selected[0])
	};

	const reopenSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => reopenSurvey(selected[0])
	};

	const deleteSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length === 0,
		onClick: () => deleteSurvey(selectedSurveys)
	};

	const restoreSurveyProps = {
		className: "ContextMenuItem",
		disabled: selected.length === 0,
		onClick: () => restoreSurvey(selected)
	};

	const cancelDeletionProps = {
		className: "ContextMenuItem",
		disabled: selected.length !== 1,
		onClick: () => cancelDeletion(selected[0])
	};

	const isAdmin = Roles.isAtLeast(Surveypal.getUser().getRole(), Roles.Admin)

	return (
		<div
			onClick={onClose}
			className={"ContextMenuWrapper"}>
			<div
				style={finalStyle}
				className={"ContextMenu"}
				ref={menuReference}>
				<div className={"ContextMenuGroup"}>
					<div {...newSurveyProps}>{translator.get("surveymodel.newSurvey")}</div>
				</div>
				<div className={"ContextMenuGroup"}>
					<div {...openSurveyProps}>{translator.get("home.open")}</div>
					<div {...duplicateSurveyProps}>{translator.get("home.duplicate")}</div>
					<div {...renameSurveyProps}>{translator.get("home.rename")}</div>
				</div>
				<div className={"ContextMenuGroup"}>
					<div {...shareSurveyProps}>{translator.get("home.shareExt")}</div>
					<div {...transferSurveyProps}>{translator.get("home.ownership")}</div>
					<div {...sharedAccessProps}>
						{
							selected.length === 1 && firstSelectedSurvey.publicShare
							?
							translator.get("share-popup.disablePublicLink")
							:
							translator.get("share-popup.enablePublicLink")
						}
					</div>
					{
						isAdmin &&
						<div {...surveyTemplateProps}>{translator.get("home.shareTemplate")}</div>
					}
				</div>
				<div className={"ContextMenuGroup"}>
					{
						(selected.length === 0 || (firstSelectedSurvey.active && !firstSelectedSurvey.published)) &&
						<div {...launchSurveyProps}>{translator.get("home.launchSelected")}</div>
					}
					{
						selected.length === 1 && firstSelectedSurvey.active && firstSelectedSurvey.published &&
						<div {...closeSurveyProps}>{translator.get("close-popup.close")}</div>
					}
					{
						selected.length === 1 && !firstSelectedSurvey.active && firstSelectedSurvey.published &&
						<div {...reopenSurveyProps}>{translator.get("reOpen-popup.open")}</div>
					}
					{
						mainFilter === FILTERS.FILTER_NAMES.DELETED_SURVEYS &&
						<div {...restoreSurveyProps}>{translator.get("home.restore")}</div>
					}
					{
						selected.length === 1 && mainFilter === FILTERS.FILTER_NAMES.DELETED_SURVEYS && (firstSelectedSurvey.deleteAt === undefined || firstSelectedSurvey.deleteAt === 0) &&
						<div {...deleteSurveyProps}>{translator.get("delete-permanent-popup.delete")}</div>
					}
					{
						selected.length === 1 && mainFilter === FILTERS.FILTER_NAMES.DELETED_SURVEYS && firstSelectedSurvey.deleteAt !== undefined && firstSelectedSurvey.deleteAt !== 0 &&
						<div {...cancelDeletionProps}>{translator.get("home.cancelPermanentDeletion")}</div>
					}
					{
						mainFilter !== FILTERS.FILTER_NAMES.DELETED_SURVEYS &&
						<div {...deleteSurveyProps}>{translator.get("home.delete")}</div>
					}
				</div>
			</div>
		</div>
	);
};

export default connect(state => ({
	surveys: state.surveys.fetchables.surveys.surveys,
	selected: state.surveys.list.selected,
	mainFilter: state.surveys.list.filters.mainFilter
}))(ContextMenu);