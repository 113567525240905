import {ACTIONS} from "./surveysActions";
import {COLUMNS} from "./components/FileListing/FileListing";
import {sortByModified} from "./utils/sorters";
import Filters from "./utils/filters";
import {FILTER_VALUES} from "./components/Filters/Filters";
import "./surveysTypes";

/**
 * @type SurveysState
 */
const initialState = {
	fetchables: {
		surveys: {
			fetched: false,
			fetching: false,
			surveys: [],
			checksum: undefined,
			lastRefresh: undefined
		},
		templates: {
			fetched: false,
			fetching: false,
			templates: [],
			lastRefresh: undefined
		}
	},
	list: {
		selected: [],
		sort: {
			column: COLUMNS.LAST_MODIFIED,
			direction: -1,
			sorter: sortByModified
		},
		filters: {
			mainFilter: Filters.FILTER_NAMES.YOUR_SURVEYS,
			tag: undefined,
			newResponsesFilter: FILTER_VALUES.ALL,
			templatesFilter: FILTER_VALUES.ALL,
			sharedFilter: FILTER_VALUES.ALL,
			automaticReportsFilter: FILTER_VALUES.ALL,
			stringFilter: ""
		},
		columns: {
			[COLUMNS.SURVEY_ID]: false,
			[COLUMNS.OWNER]: true,
			[COLUMNS.LAST_MODIFIED]: true,
			[COLUMNS.RESPONSES]: true,
			[COLUMNS.NEW_RESPONSES]: false,
			[COLUMNS.PUBLIC_SHARE]: false,
			[COLUMNS.SURVEY_STATUS]: true
		}
	},
	informationPanel: {
		surveyInformation: {
			fetched: false,
			fetching: false,
			surveyInformation: undefined
		},
		responseTimeline: {
			fetched: false,
			fetching: false,
			responseTimeline: undefined
		},
		preview: {
			fetched: false,
			fetching: false,
			preview: undefined
		}
	},
	popups: {
		surveyTemplate: {
			show: false,
			surveyId: undefined
		},
		surveyDeletionErrorPopup: {
			show: false,
			error: undefined
		}
	}
};

const surveysReducer = (previousState = initialState, action) => {
	switch (action.type)
	{
		case ACTIONS.CLEAR_STATE:
			return initialState;
		case ACTIONS.SET_SURVEYS:
		{
			const sorter = previousState.list.sort.sorter;
			const direction = previousState.list.sort.direction;
			const sortWithDirection = (a, b) => sorter(a, b) * direction;
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...action.surveys,
						surveys: action.surveys.surveys.sort(sortWithDirection)
					}
				}
			};
		}
		case ACTIONS.DELETE_SURVEY:
		{
			const deleter = (survey) => {
				if (survey.id === action.surveyId) return {
					...survey,
					deleted: true
				};

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(deleter)
					}
				}
			};
		}
		case ACTIONS.UNDELETE_SURVEY:
		{
			const undeleter = (survey) => {
				if (survey.id === action.surveyId) return {
					...survey,
					deleted: false
				};

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(undeleter)
					}
				}
			};
		}
		case ACTIONS.REMOVE_SURVEY:
		{
			const surveyRemover = (survey) => survey.id !== action.surveyId;
			const selectionRemover = (selectedSurveyId) => selectedSurveyId !== action.surveyId;

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.filter(surveyRemover)
					}
				},
				list: {
					...previousState.list,
					selected: previousState.list.selected.filter(selectionRemover)
				}
			};
		}
		case ACTIONS.ADD_SURVEY_DELETION_TASK:
		{
			const deletionTaskAdder = (survey) => {
				if (survey.id === action.surveyId)
				{
					delete survey.deleting;
					return {
						...survey,
						deleteAt: action.deleteAt
					}
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(deletionTaskAdder)
					}
				}
			};
		}
		case ACTIONS.REMOVE_SURVEY_DELETION_TASK:
		{
			const deletionTaskRemover = (survey) => {
				if (survey.id === action.surveyId)
				{
					delete survey.deleteAt
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(deletionTaskRemover)
					}
				}
			};
		}
		case ACTIONS.MARK_AS_BEING_DELETED:
		{
			const marker = (survey) => {
				if (survey.id === action.surveyId)
				{
					return {
						...survey,
						deleting: true
					};
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(marker)
					}
				}
			};
		}
		case ACTIONS.UNMARK_AS_BEING_DELETED:
		{
			const unmarker = (survey) => {
				if (survey.id === action.surveyId)
				{
					delete survey.deleting;
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(unmarker)
					}
				}
			};
		}
		case ACTIONS.SET_NEW_TAGS:
		{
			const retagger = (survey) => {
				if (survey.id === action.surveyId)
				{
					return {
						...survey,
						tags: action.newTags
					};
				}

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(retagger)
					}
				}
			};
		}
		case ACTIONS.MARK_SURVEYS_AS_READ:
		{
			const marker = (survey) => {
				if (action.surveyIds.indexOf(survey.id) !== -1) survey.newAnswerCount = 0;

				return survey;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(marker)
					}
				}
			};
		}
		case ACTIONS.RENAME_SURVEY:
		{
			const renamer = (survey) => {
				if (survey.id !== action.surveyId) return survey;

				return {
					...survey,
					name: action.newName
				};
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(renamer)
					}
				}
			}
		}
		case ACTIONS.SET_TEMPLATES:
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: action.templates
				}
			};
		case ACTIONS.UPDATE_TEMPLATE:
		{
			const updater = (template) => {
				if (template.id === action.newTemplate.id) return action.newTemplate;

				return template;
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: {
						...previousState.fetchables.templates,
						templates: previousState.fetchables.templates.templates.map(updater)
					}
				}
			};
		}
		case ACTIONS.DELETE_TEMPLATE:
		{
			const deleter = (template) => template.id !== action.deletedTemplate.id;

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: {
						...previousState.fetchables.templates,
						templates: previousState.fetchables.templates.templates.filter(deleter)
					}
				}
			};
		}
		case ACTIONS.CREATE_TEMPLATE:
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					templates: {
						...previousState.fetchables.templates,
						templates: [...previousState.fetchables.templates.templates, action.newTemplate]
					}
				}
			};
		case ACTIONS.ADD_PUBLIC_SHARE:
			const publicShareAdder = (survey) => {
				if (survey.id !== action.surveyId) return survey;

				return {
					...survey,
					publicShare: true,
					publicSurveyUrl: action.link
				};
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(publicShareAdder)
					}
				}
			};
		case ACTIONS.REMOVE_PUBLIC_SHARE:
		{
			const publicShareRemover = (survey) => {
				if (survey.id !== action.surveyId)
				{
					return survey;
				}

				return {
					...survey,
					publicShare: false,
					publicSurveyUrl: null
				};
			};

			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.map(publicShareRemover)
					}
				}
			};
		}
		case ACTIONS.SET_CHECKSUM:
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						checksum: action.checksum
					}
				}
			};
		case ACTIONS.SET_SELECTED:
			return {
				...previousState,
				list: {
					...previousState.list,
					selected: [action.surveyId]
				}
			};
		case ACTIONS.ADD_SELECTED:
			return {
				...previousState,
				list: {
					...previousState.list,
					selected: [...previousState.list.selected, action.surveyId]
				}
			};
		case ACTIONS.SELECT_RANGE:
		{
			const firstSelectedId = previousState.list.selected[0];
			const firstSelectedIndex = previousState.fetchables.surveys.surveys.findIndex(survey => survey.id === firstSelectedId);
			const rangeSelectedId = action.surveyId;
			const rangeSelectedIndex = previousState.fetchables.surveys.surveys.findIndex(survey => survey.id === rangeSelectedId);
			const startRangeFrom = Math.min(firstSelectedIndex, rangeSelectedIndex);
			const rangeLength = Math.abs(firstSelectedIndex - rangeSelectedIndex) + 1;
			const newSelected = Array(rangeLength).fill(0).map((_, i) => previousState.fetchables.surveys.surveys[startRangeFrom + i].id);

			return {
				...previousState,
				list: {
					...previousState.list,
					selected: newSelected
				}
			};
		}
		case ACTIONS.UNSELECT:
			return {
				...previousState,
				list: {
					...previousState.list,
					selected: previousState.list.selected.filter(selected => selected !== action.surveyId)
				}
			};
		case ACTIONS.UNSELECT_ALL:
			return {
				...previousState,
				list: {
					...previousState.list,
					selected: []
				}
			};
		case ACTIONS.SET_SORT:
		{
			const sorter = action.sort.sorter;
			const direction = action.sort.direction;
			const sortWithDirection = (a, b) => sorter(a, b) * direction;
			return {
				...previousState,
				fetchables: {
					...previousState.fetchables,
					surveys: {
						...previousState.fetchables.surveys,
						surveys: previousState.fetchables.surveys.surveys.sort(sortWithDirection)
					}
				},
				list: {
					...previousState.list,
					sort: action.sort
				}
			};
		}
		case ACTIONS.TOGGLE_COLUMN:
			return {
				...previousState,
				list: {
					...previousState.list,
					columns: {
						...previousState.list.columns,
						[action.column]: !previousState.list.columns[action.column]
					}
				}
			};
		case ACTIONS.CHANGE_MAIN_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						mainFilter: action.mainFilter,
						tag: undefined
					}
				}
			}
		case ACTIONS.FILTER_BY_TAG:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						mainFilter: Filters.FILTER_NAMES.TAGGED_SURVEYS,
						tag: action.tag
					}
				}
			};
		case ACTIONS.SET_NEW_RESPONSES_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						newResponsesFilter: action.newResponsesFilter
					}
				}
			};
		case ACTIONS.SET_TEMPLATES_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						templatesFilter: action.templatesFilter
					}
				}
			};
		case ACTIONS.SET_SHARED_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						sharedFilter: action.sharedFilter
					}
				}
			};
		case ACTIONS.SET_AUTOMATIC_REPORTS_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						automaticReportsFilter: action.automaticReportsFilter
					}
				}
			};
		case ACTIONS.SET_STRING_FILTER:
			return {
				...previousState,
				list: {
					...previousState.list,
					filters: {
						...previousState.list.filters,
						stringFilter: action.stringFilter
					}
				}
			};
		case ACTIONS.SET_RESPONSE_TIMELINE:
			return {
				...previousState,
				informationPanel: {
					...previousState.informationPanel,
					responseTimeline: action.responseTimeline
				}
			};
		case ACTIONS.SET_PREVIEW:
			return {
				...previousState,
				informationPanel: {
					...previousState.informationPanel,
					preview: action.preview
				}
			};
		case ACTIONS.SET_SURVEY_INFORMATION:
			return {
				...previousState,
				informationPanel: {
					...previousState.informationPanel,
					surveyInformation: action.surveyInformation
				}
			}
		case ACTIONS.TOGGLE_SURVEY_TEMPLATE_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					surveyTemplate: {
						...previousState.popups.surveyTemplate,
						show: !previousState.popups.surveyTemplate.show
					}
				}
			};
		case ACTIONS.TOGGLE_SURVEY_DELETION_ERROR_POPUP:
			return {
				...previousState,
				popups: {
					...previousState.popups,
					surveyDeletionErrorPopup: {
						...previousState.popups.surveyDeletionErrorPopup,
						show: action.newState,
						error: action.error
					}
				}
			};
		default:
			return previousState;
	}
};

export default surveysReducer;